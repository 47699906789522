import { Trans } from '@lingui/react/macro';
import { labelEntitySelectors } from '@integration-frontends/integration/core/application';
import { removeLabelFilter } from '@integration-frontends/integration/core/model';
import React from 'react';
import { useSelector } from 'react-redux';
import { BFAnchor, IconLabel, IconSize } from '@integration-frontends/common/ui';
import classNames from 'classnames';
import { useSearch } from '../show-page/search/use-search';

export function LabelPathList({ searchParams }) {
  const { id, path } = searchParams.labelFilter;
  const { search } = useSearch();
  function clear() {
    search(removeLabelFilter(searchParams));
  }

  const pathLabels = path.map((pathId) => {
    return pathId !== id ? (
      <>
        <LabelPathNode key={pathId} labelId={pathId} lastStop={false} /> <LabelListSeparator />
      </>
    ) : (
      <></>
    );
  });

  return (
    <div className="label-path-list">
      <div className="label-path-list-icon">
        <IconLabel iconSize={IconSize.Medium} />
      </div>
      {pathLabels}
      <LabelPathNode labelId={id} lastStop={true} />
      <BFAnchor className="label-list-clear-btn" onClick={clear}>
        <Trans>Clear labels</Trans>
      </BFAnchor>
    </div>
  );
}

function LabelPathNode({ labelId, lastStop }) {
  const [label] = useSelector(labelEntitySelectors.selectLabelById(labelId));
  return (
    <div className={classNames('label-path-node', { 'last-node': lastStop })}>{label?.name}</div>
  );
}

function LabelListSeparator() {
  return <div className="label-list-separator">/</div>;
}
