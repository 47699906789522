import { t } from '@lingui/core/macro';
import { IconEmbed } from '@integration-frontends/common/ui';
import { IconPlacementOptions } from '@integration-frontends/common/ui/icons/icon-placement-options';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  assetDetailsPageEntered,
  assetEntitySelectors,
  attachmentEntitySelectors,
  containerSelectors,
  IntegrationRootState,
} from '@integration-frontends/integration/core/application';
import { Attachment, positionComparator } from '@integration-frontends/integration/core/model';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AttachmentSelectorFeaturesService } from '@integration-frontends/integration/infrastructure/isomorphic';
import * as qs from 'qs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  PageContainer,
  PageContent,
  PageSection,
  PageSectionContent,
  PageSubSection,
} from '../../../common/layout/page';
import { ATTACHMENT_SELECTOR_FEATURES_TOKEN, AttachmentSelectorFeature } from '../../../features';
import {
  AttachmentActionProviderContext,
  createBasicActionContainer,
} from '../../common/attachment-actions/attachment-actions-model';
import { AssetAttachments } from './asset-attachments/asset-attachments';
import { AssetDescription } from './asset-description';
import './asset-details-page.scss';
import { AssetDetailsContainer } from './asset-details/asset-details-container';
import { AssetTags } from './asset-tags';
import { AttachmentPreview } from './attachment-preview';
import { CdnEmbedOptionsContainer } from './cdn-embed-options/cdn-embed-options-container';
import { CustomFields } from './custom-fields';
import { AssetDetailsPageHeader } from './header';
import { PlacementOptionsContainer } from './placement-options/placement-options-container';

export interface AssetDetailsPageProps {
  assetSelect: (assetId: string) => void;
}

export function AssetDetailsPage({ assetSelect }: AssetDetailsPageProps) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { assetId, containerId } = useParams<{ assetId: string; containerId: string }>();
  const featuresService: AttachmentSelectorFeaturesService = DI_CONTAINER.get(
    ATTACHMENT_SELECTOR_FEATURES_TOKEN,
  );
  const urlSearch = qs.parse(location.search.substr(1)) as {
    selectedAttachmentId?: string;
  };

  const selectedContainer = useSelector((state) =>
    containerSelectors.selectById(state, containerId),
  );
  const asset = useSelector((state: IntegrationRootState) =>
    assetEntitySelectors.selectById(state, assetId),
  );
  const attachments = useSelector(attachmentEntitySelectors.byAssetId(assetId)).sort(
    positionComparator,
  );
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(null);
  const selectedAttachment = attachments.find(
    (attachment) => attachment.id === selectedAttachmentId,
  );
  const placementOptionsSectionRef = useRef(null);
  const cdnEmbedSectionRef = useRef(null);
  const showCdnEmbedOptions = 
    featuresService.hasFeature(AttachmentSelectorFeature.CdnEmbed) &&
    selectedContainer?.embedAssetSetting &&
    asset?.availability !== 'view_only';

  useEffect(() => {
    assetSelect && assetSelect(assetId);
    dispatch(assetDetailsPageEntered({ assetId }));
  }, [assetId]);

  useEffect(() => {
    if (!selectedAttachmentId && urlSearch.selectedAttachmentId) {
      setSelectedAttachmentId(urlSearch.selectedAttachmentId);
    } else if (!selectedAttachmentId && attachments?.[0]) {
      setSelectedAttachmentId(attachments[0].id);
    }
  }, [attachments]);

  const attachmentSelected = useCallback(
    (attachment: Attachment) => {
      setSelectedAttachmentId(attachment.id);
    },
    [assetId],
  );

  const OpenPlacementOptionsActionContainer = createBasicActionContainer(
    'OPEN_PLACEMENT_OPTIONS',
    t`Placement options`,
    ([attachment]) => {
      setSelectedAttachmentId(attachment.id);
      placementOptionsSectionRef?.current.open();
      setTimeout(() => placementOptionsSectionRef?.current.scrollIntoView(), 200);
    },
    IconPlacementOptions,
  );

  const OpenCdnEmbedOptionsActionContainer = createBasicActionContainer(
    'OPEN_CDN_EMBED',
    t`CDN embed link options`,
    ([attachment]) => {
      setSelectedAttachmentId(attachment.id);
      cdnEmbedSectionRef?.current.open();
      setTimeout(() => cdnEmbedSectionRef?.current.scrollIntoView(), 200);
    },
    IconEmbed,
  );

  if (!asset) return null;

  return (
    <PageContainer
      data-testid="asset-details-page"
      aria-modal="true"
      className="h-full overflow-y-auto"
      role="dialog"
    >
      <AssetDetailsPageHeader asset={asset} container={selectedContainer} />

      <PageContent>
        <PageSection id="asset-details-section">
          <PageSubSection title={asset.name}>
            <PageSectionContent>
              <AttachmentActionProviderContext.Provider
                value={{
                  actionContainers: [
                    ...(featuresService.hasFeature(AttachmentSelectorFeature.PlacementOptions)
                      ? [OpenPlacementOptionsActionContainer]
                      : []),
                    ...(showCdnEmbedOptions
                      ? [OpenCdnEmbedOptionsActionContainer]
                      : []),
                  ],
                }}
              >
                <AssetAttachments
                  asset={asset}
                  containerId={selectedContainer?.id}
                  onAttachmentClick={attachmentSelected}
                  selectedAttachmentId={selectedAttachmentId}
                />
              </AttachmentActionProviderContext.Provider>
            </PageSectionContent>
          </PageSubSection>

          <PageSectionContent aria-label={t`Attachment image preview`}>
            <AttachmentPreview asset={asset} attachment={selectedAttachment} />
          </PageSectionContent>
        </PageSection>

        {featuresService.hasFeature(AttachmentSelectorFeature.PlacementOptions) &&
          asset?.availability !== 'view_only' && (
            <PageSection
              ref={placementOptionsSectionRef}
              data-testid="placement-options-section"
              expandable={true}
              initialExpanded={false}
              title={t`Placement options`}
            >
              <PageSectionContent>
                <PlacementOptionsContainer attachment={selectedAttachment} />
              </PageSectionContent>
            </PageSection>
          )}

        {showCdnEmbedOptions && (
            <PageSection
              ref={cdnEmbedSectionRef}
              data-testid="cdn-embed-options-section"
              expandable={true}
              initialExpanded={false}
              title={t`CDN embed link options`}
            >
              <PageSectionContent>
                <CdnEmbedOptionsContainer attachment={selectedAttachment} />
              </PageSectionContent>
            </PageSection>
          )}

        <AssetDetailsContainer asset={asset} attachment={selectedAttachment} />

        <AssetDescription asset={asset} />

        <CustomFields asset={asset} />

        <AssetTags asset={asset} />
      </PageContent>
    </PageContainer>
  );
}
