import styled from '@emotion/styled';
import { BFImage } from '@integration-frontends/common/ui';
import { DI_CONTAINER } from '@integration-frontends/core';
import {
  Asset,
  BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  IBrandfolderWebsiteService,
} from '@integration-frontends/integration/core/model';
import { border } from '@smartsheet/design-tokens';
import { Spinner } from '@smartsheet/lodestar-core';
import React, { HTMLAttributes } from 'react';

export interface AssetImageProps {
  asset: Asset;
  checkeredBackground?: boolean;
  displayActualThumbnailSize?: boolean;
  backgroundColor?: string;
  isFileProcessing?: boolean;
  responsive?: boolean;
}

export function AssetThumbnailImage({
  asset,
  checkeredBackground,
  isFileProcessing = false,
  className,
  displayActualThumbnailSize,
  backgroundColor,
  responsive,
  ...props
}: AssetImageProps & HTMLAttributes<HTMLImageElement>) {
  const bfWebsiteService: IBrandfolderWebsiteService = DI_CONTAINER.get(
    BRANDFOLDER_WEBSITE_SERVICE_TOKEN,
  );

  return (
    <AssetThumbnailImageContaier
      checkeredBackground={checkeredBackground}
      displayActualThumbnailSize={displayActualThumbnailSize}
      backgroundColor={backgroundColor}
    >
      {isFileProcessing ? (
        <Spinner size="medium" />
      ) : (
        <BFImage
          src={asset?.thumbnailUrl}
          alt={asset?.name}
          fallbackUrl={bfWebsiteService.getFileThumbnailUrl(asset?.extension)}
          className={'bf-image'}
          responsive={responsive}
          {...props}
        />
      )}
    </AssetThumbnailImageContaier>
  );
}

const AssetThumbnailImageContaier = styled.button<{
  checkeredBackground?: boolean;
  displayActualThumbnailSize?: boolean;
  backgroundColor?: string;
}>`
  position: relative;
  height: 100%;
  width: 100%;
  :focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px #6097d2;
  }

  ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor};`}

  ${({ displayActualThumbnailSize }) =>
    displayActualThumbnailSize &&
    `
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 150px;
  padding: 2% 3%;
  `}
  
  ${({ checkeredBackground }) =>
    checkeredBackground &&
    `background-image: url(https://cdn.bfldr.com/27C9EC93/at/q7v8py-ft5thk-e0hkgw/background-transparent-square.png?auto=webp&format=png);`}
  
  .bf-image {
    border-radius: ${border.radius10};
    ${({ displayActualThumbnailSize }) =>
      displayActualThumbnailSize
        ? `max-width: 100%; 
    max-height: 100%;`
        : `position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    `}
  }

  .file-processing-img {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 40%;
    width: 40%;
  }
`;
