import { t } from '@lingui/core/macro';
import { uploadAssetsSelectors } from '@integration-frontends/integration/core/application';
import React from 'react';
import { useSelector } from 'react-redux';
import { PageContainer, PageScrollContainer } from '../common/layout/page';
import { IntegrationPageLoader } from '../common/page-loader/page-loader';
import { TitleFlexContainer } from './common';
import { UploadForm } from './upload-form';
import { UploadSuccess } from './upload-success';

export function AttachmentUploader() {
  const success = useSelector(uploadAssetsSelectors.success);
  const loading = useSelector(uploadAssetsSelectors.loading);
  const assetUploads = useSelector(uploadAssetsSelectors.assets);
  const renderTitle = () => {
    if (!assetUploads?.length) {
      return t`Upload files`;
    }
    if (assetUploads?.length === 1) {
      return assetUploads[0].name;
    }
    return t`Upload ${assetUploads?.length} files`;
  };
  const title = renderTitle();

  return (
    <PageContainer>
      <PageScrollContainer className="h-full">
        <div className="flex min-h-0 flex-grow flex-col">
          {loading ? (
            <IntegrationPageLoader />
          ) : (
            <>
              <TitleFlexContainer>{title}</TitleFlexContainer>
              {success ? <UploadSuccess /> : <UploadForm />}
            </>
          )}
        </div>
      </PageScrollContainer>
    </PageContainer>
  );
}
