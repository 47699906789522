import {
  FieldParameter,
  getResponseDataOrDefault,
  getResponseIncluded,
  getResponseListDataOrDefault,
  IncludedBrandfolderDto,
  Relationship,
} from '@integration-frontends/common/brandfolder-api';
import {
  Brandfolder,
  BrandfolderCustomField,
  BrandfolderFileTypeAggregates,
  BrandfolderTag,
  CustomFieldValueOption,
  IBrandfolderRepo,
} from '@integration-frontends/integration/core/model';
import { injectable } from 'inversify';
import { flatten, map, pipe, prop, uniqBy } from 'ramda';
import { RepoBase } from './repo-base';
import {
  mapBrandfolder,
  mapBrandfolderCustomField,
  mapBrandfolderFileTypeAggregates,
  mapBrandfolderTag,
  mapCustomFieldValueList,
  mapIncludedBrandfolder,
} from './model';

@injectable()
export class BrandfolderRepo extends RepoBase implements IBrandfolderRepo {
  getBrandfolder: IBrandfolderRepo['getBrandfolder'] = async (brandfolderId: string) => {
    return this.brandfolderApi
      .fetchBrandfolder(await this.getApiKey(), brandfolderId, {
        fields: [FieldParameter.CardImage, FieldParameter.AssetCount, FieldParameter.EmbedAssetSetting],
        include: [Relationship.ORGANIZATION],
      })
      .then(getResponseDataOrDefault)
      .then(mapBrandfolder)
      .then(async (brandfolder) => {
        if (brandfolder) return brandfolder;

        // if brandfolder is undefined then we'll have to fetch it as a relation
        const brandfolders = await this._getRelatedBrandfolders();
        return brandfolders.find((bf) => bf.id === brandfolderId);
      });
  };

  listBrandfolders = async (): Promise<Brandfolder[]> => {
    return Promise.all([this._getAccessibleBrandfolders(), this._getRelatedBrandfolders()]).then(
      pipe(flatten, uniqBy(prop('id'))),
    );
  };

  private async _getAccessibleBrandfolders(): Promise<Brandfolder[]> {
    return this.brandfolderApi
      .listBrandfolders(await this.getApiKey(), {
        fields: [FieldParameter.CardImage, FieldParameter.AssetCount],
        include: [Relationship.ORGANIZATION],
      })
      .then(getResponseListDataOrDefault)
      .then(map(mapBrandfolder));
  }

  private async _getRelatedBrandfolders(): Promise<Brandfolder[]> {
    return Promise.all([
      this.brandfolderApi.listCollections(await this.getApiKey(), {
        include: [Relationship.BRANDFOLDER],
      }),
      this.brandfolderApi.listOrganizations(await this.getApiKey(), {
        include: [Relationship.COLLECTIONS],
      }),
    ]).then(([collectionsResponse, organizationsResponse]) => {
      return getResponseListDataOrDefault(collectionsResponse).map((collectionDto) => {
        const includedBrandfolderDto = getResponseIncluded(collectionsResponse).find(
          (included) =>
            included.id === collectionDto?.relationships[Relationship.BRANDFOLDER].data.id,
        ) as IncludedBrandfolderDto;
        const organization = getResponseDataOrDefault(organizationsResponse).find(
          (organizationDto) =>
            organizationDto?.relationships[Relationship.COLLECTIONS].data.find(
              (c) => c?.id === collectionDto?.id,
            ),
        );

        return mapIncludedBrandfolder(includedBrandfolderDto, organization?.id);
      });
    });
  }

  getBrandfolderCustomFields = async (brandfolderId: string): Promise<BrandfolderCustomField[]> => {
    const apiKey = await this.getApiKey();
    const searchableAggregations = await this.brandfolderApi.getBrandfolderSearchableAggregations(
      apiKey,
      brandfolderId,
    );
    const customFieldsResponse = await this.brandfolderApi.getBrandfolderCustomFieldsKeys(
      apiKey,
      brandfolderId,
    );

    return getResponseListDataOrDefault(customFieldsResponse).map((customFieldDto) =>
      mapBrandfolderCustomField(brandfolderId, customFieldDto, searchableAggregations),
    );
  };

  getBrandfolderCustomFieldValues = async (
    brandfolderId: string,
    customFieldKey: string,
  ): Promise<CustomFieldValueOption[]> => {
    const apiKey = await this.getApiKey();

    const customFieldValuesResponse =
      await this.brandfolderApi.getBrandfolderCustomFieldValuesOptions(
        apiKey,
        brandfolderId,
        customFieldKey,
      );

    return mapCustomFieldValueList(
      customFieldValuesResponse.data.custom_field_values,
      customFieldKey,
    );
  };

  getBrandfolderFileTypeAggregates = async (
    brandfolderId: string,
  ): Promise<BrandfolderFileTypeAggregates[]> => {
    return await this.brandfolderApi
      .getBrandfolderSearchableAggregations(await this.getApiKey(), brandfolderId)
      .then((searchableAggregations) =>
        mapBrandfolderFileTypeAggregates(brandfolderId, searchableAggregations),
      );
  };

  getBrandfolderTags = async (brandfolderId: string): Promise<BrandfolderTag[]> => {
    const apiKey = await this.getApiKey();
    const searchableAggregations = await this.brandfolderApi.getBrandfolderSearchableAggregations(
      apiKey,
      brandfolderId,
    );

    return searchableAggregations.tags.map((tag) => mapBrandfolderTag(brandfolderId, tag));
  };
}
